import React from 'react'
import { Skeleton as AntdSkeleton } from 'antd'

const TITLE_WIDTH = 150
const ROW_WIDTH = 200

export const TitleRow = () => <AntdSkeleton active title={{ width: TITLE_WIDTH }} paragraph={false} />
export const ValueRow = ({
  icon,
  long,
  className = '',
  extraLength,
}: {
  icon?: boolean
  long?: boolean
  className?: string
  extraLength?: number
}) => (
  <AntdSkeleton
    active
    avatar={icon ? { size: 'small', shape: 'square' } : false}
    paragraph={false}
    title={{ width: ROW_WIDTH + (long ? 170 : extraLength ? extraLength : 0) }}
    className={className}
  />
)

export const DescriptionRow = ({ long, extraLength }: { long?: boolean; extraLength?: number }) => (
  <AntdSkeleton
    active
    paragraph={{ width: ROW_WIDTH + (long ? 170 : extraLength ? extraLength : 0), rows: 3 }}
    title={false}
  />
)

export const RateFieldValue = () => (
  <div className="flex space-between items-center mb-4">
    <ValueRow icon />
    <ValueRow long />
  </div>
)

export const RateFieldLongValue = () => (
  <div className="flex space-between items-start">
    <ValueRow icon className="-mt-2" />
    <DescriptionRow long />
  </div>
)
