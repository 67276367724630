import React from 'react'

import { RateFieldValue, TitleRow } from 'ui-v2'

export const Skeleton = () => (
  <div className="pr-[30%]">
    <TitleRow />
    <div className="h-6" />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />

    <div className="h-10" />

    <TitleRow />
    <div className="h-6" />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />

    <div className="h-10" />

    <TitleRow />
    <div className="h-6" />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />

    <TitleRow />
    <div className="h-6" />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />

    <TitleRow />
    <div className="h-6" />
    <RateFieldValue />
    <RateFieldValue />
    <RateFieldValue />
  </div>
)
