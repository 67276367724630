// TODO: refactor
import React, { useMemo, useCallback, useState } from 'react'
import { Moment } from 'moment'

import { notification } from 'helpers'

import { Modal } from 'ui'
import { useDimensions } from 'hooks'
import { NORMAL_PC_BREAK_POINT } from 'constants/breakpoints'
import { HotelListItem } from 'types/domain/HotelListItem'
import { RateDetails } from 'types/domain/RateDetails'
import { useCreateCheckup } from 'api/checkups/createCheckup'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { stringifyMomentDate } from 'helpers/date'

import { CompareRatesTable, CompareRatesMobile } from './components'
import createSections from './createSections'
import styles from './CompareRatesModal.module.scss'

type Props = {
  onClose: () => void
  corporateCodes: string[]
  hotel: HotelListItem
  firstRateToCompare: RateDetails
  startDateFirstRate: Moment
  endDateFirstRate: Moment
  adultsCount: number
  childrenCount: number
}

const CompareRatesModal = (props: Props) => {
  const {
    onClose,
    corporateCodes,
    firstRateToCompare,
    startDateFirstRate,
    endDateFirstRate,
    adultsCount,
    childrenCount,
    hotel,
  } = props
  const { width } = useDimensions()
  const { headers, isSelectedSabre } = useGdsSelectContext()

  const [dateSecondRate, setDateSecondRate] = useState<Moment | undefined>()
  const [bookingCodeSecondRate, setBookingCodeSecondRate] = useState(firstRateToCompare.rate_plan)

  const createCheckup = useCreateCheckup({
    queryOptions: { onError: (error) => notification.error({ message: error.message }) },
  })

  const createdCheckup = useMemo(() => createCheckup.data?.data, [createCheckup.data?.data])

  const secondRateListToCompare = useMemo(() => createdCheckup?.rates || [], [createdCheckup?.rates])
  const secondRateToCompare = useMemo(
    () => secondRateListToCompare.find((rate) => rate.rate_plan === bookingCodeSecondRate),
    [bookingCodeSecondRate, secondRateListToCompare],
  )

  const onSelectDate = useCallback(
    (date: Moment) => {
      setDateSecondRate(date)

      createCheckup.mutate({
        headers,
        body: {
          hotel_id: hotel.id,
          mode: 'full',
          start_date: stringifyMomentDate(startDateFirstRate),
          end_date: stringifyMomentDate(endDateFirstRate),
          corporate_codes: corporateCodes,
          adults: adultsCount,
          children: childrenCount,
        },
      })
    },
    [
      corporateCodes,
      createCheckup,
      headers,
      hotel.id,
      adultsCount,
      childrenCount,
      startDateFirstRate,
      endDateFirstRate,
    ],
  )

  const sections = useMemo(
    () => createSections(isSelectedSabre, firstRateToCompare, secondRateToCompare),
    [firstRateToCompare, isSelectedSabre, secondRateToCompare],
  )

  // TODO: should refactor
  const filteredSections = useMemo(
    () =>
      sections
        .flatMap((section) => (section ? [section] : []))
        .map((section) => ({
          ...section,
          fields: section.fields
            .flatMap((field) => (field ? [field] : []))
            .map((field) => ({
              ...field,
              values1: field.values1.filter((value) => value),
              values2: field.values2.filter((value) => value),
              sublines: (field.sublines || []).map((subline) => ({
                ...subline,
                values1: subline.values1.filter((value) => value),
                values2: subline.values2.filter((value) => value),
              })),
            })),
        })),
    [sections],
  )

  return (
    <>
      {width > NORMAL_PC_BREAK_POINT ? (
        <Modal className={styles.modal} visible onCancel={onClose} bodyStyle={{ padding: 0 }} closable={false}>
          <CompareRatesTable
            onClose={onClose}
            currentDate={startDateFirstRate}
            currentRate={firstRateToCompare}
            isLoading={createCheckup.isLoading}
            selectedDate={dateSecondRate}
            setSelectedDate={onSelectDate}
            hotel={hotel}
            rate={secondRateToCompare}
            rates={secondRateListToCompare}
            onRateChange={setBookingCodeSecondRate}
            sections={filteredSections}
            showErrors={!createCheckup.isLoading && !!secondRateToCompare}
          />
        </Modal>
      ) : (
        <CompareRatesMobile
          onClose={onClose}
          isLoading={createCheckup.isLoading}
          currentDate={startDateFirstRate}
          currentRate={firstRateToCompare}
          selectedDate={dateSecondRate}
          setSelectedDate={onSelectDate}
          rate={secondRateToCompare}
          rates={secondRateListToCompare}
          onRateChange={setBookingCodeSecondRate}
          sections={filteredSections}
        />
      )}
    </>
  )
}

export default CompareRatesModal
