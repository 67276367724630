import { QueryKey, useMutation, UseMutationOptions } from 'react-query'

import api from 'api/index'
import { ApiError } from 'api/types/ApiError'
import { RequestHeadersWithGdsScope } from 'api/types/RequestHeadersWithGdsScope'
import { Hotel } from 'types/domain/Hotel'

import { CHECKUPS_ENDPOINT_KEY } from './constants'

type CreateCheckupRequestBody = {
  hotel_id: number
  mode: 'full' | 'hotel_details_only' | 'rates_only'
  start_date: string
  end_date: string
  corporate_codes?: string[]
  rate_category_codes?: string[]
  adults: number
  children: number
}

type CreateCheckupResponse = {
  data: Hotel
}

export const createCheckup = ({
  headers,
  body,
}: {
  headers: RequestHeadersWithGdsScope
  body: CreateCheckupRequestBody
}): Promise<CreateCheckupResponse> => api.post(`/checkups`, body, { headers })

export const useCreateCheckup = ({
  queryOptions,
  customQueryKey,
}: {
  queryOptions?: UseMutationOptions<
    Awaited<ReturnType<typeof createCheckup>>,
    ApiError,
    { headers: RequestHeadersWithGdsScope; body: CreateCheckupRequestBody }
  >
  customQueryKey?: QueryKey
} = {}) => useMutation(customQueryKey || [CHECKUPS_ENDPOINT_KEY], (args) => createCheckup(args), queryOptions)
