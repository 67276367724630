import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import moment, { Moment } from 'moment'
import { ArrowRightAlt } from '@material-ui/icons'

import { useGetHotelResource } from 'api/hotels/getHotelResource'
import { useCreateCheckup } from 'api/checkups/createCheckup'
import { useGetRateRulesResource } from 'api/rates/getRateRulesResource'
import { useGetHotelListResource } from 'api/hotels/getHotelListResource'

import { HotelListItem } from 'types/domain/HotelListItem'
import { RateDetails } from 'types/domain/RateDetails'
import { Hotel } from 'types/domain/Hotel'

import { notification } from 'helpers'
import { parseOptionalInt } from 'helpers/number'
import { stringifyMomentDate } from 'helpers/date'

import PageLoader from 'ui/PageLoader'
import useIsTablet from 'hooks/useIsTablet'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import useHotelSelectContext from 'components/HotelSelect/useHotelSelectContext'
import HotelSelectModal from 'modules/HotelsModule/components/SelectHotelModal'
import { useGetCheckups } from 'api/checkups/getCheckups'
import { useGetRecentHotelSearchListResource } from 'api/hotels/getHotelSearchListResource'
import { useIsTravelAgent } from 'hooks'
import { SABRE_KEY, TRAVEL_PORT_KEY } from 'constants/gds'
import { RateRules } from 'types/domain/RateRules'
import { API_WARNINGS } from 'constants/apiWarnings'

import { Tabs } from 'ui-v2'
import Header from './components/Header'
import BookingCodeList from './components/BookingCodeList/BookingCodeList'
import RateDetailsTab from './components/RateDetailsTab'
import RateRulesTab from './components/RateRulesTab'
import CompareRatesModal from './components/CompareRatesModal'
import OTAView from './components/OTAView'
import NoData from './components/NoData'
import Params from './components/BookingCodeList/components/Params'
import ReportIssueModal from './components/ReportIssueModal'
import NotifyIssueActionButtons from './components/NotifyIssueActionButtons'

import styles from './HotelPage.module.scss'
import unavailableImage from 'assets/unavailable.svg'
import noDataImage from 'assets/noData.svg'
import Unavailable from './components/Unavailable'

const RATE_DETAILS_TAB = {
  key: 'details',
  label: 'Rate Details',
}
const RATE_RULES_TAB = {
  key: 'rules',
  label: 'Rate Rules',
}
const TABS = [RATE_DETAILS_TAB, RATE_RULES_TAB]
const DEFAULT_TAB_KEY = RATE_DETAILS_TAB.key

const OTA_VIEW_PATH = 'ota-view'

const HotelPage = () => {
  const { push: routerPush, replace: routerReplace, location: routerLocation } = useHistory()
  const { userId, tabKey: selectedTabKey } = useParams<{ hotelId: string; userId: string; tabKey?: string }>()
  const isTablet = useIsTablet()

  const urlQuery = useMemo(() => new URLSearchParams(routerLocation.search), [routerLocation.search])
  const [initialCheckupRateId, setInitialCheckupRateId] = useState(urlQuery.get('checkup_rate_id'))
  const [initialHotelId, setInitialHotelId] = useState(urlQuery.get('hotel_id'))
  const [initialTravelPortId] = useState(urlQuery.get('travelport_id'))
  const [initialBookingCode, setInitialBookingCode] = useState(urlQuery.get('code'))
  const [initialSabreId] = useState(urlQuery.get('sabre_id'))
  // todo: before integration with a backend - move Params search state to context api?
  const [selectedStartDate, setSelectedStartDate] = useState<Moment>(moment().add(1, 'day'))
  const [selectedEndDate, setSelectedEndDate] = useState<Moment>(moment().add(3, 'days'))
  const [adultsCount, setAdultsCount] = useState<number>(1)
  const [childrenCount, setChildrenCount] = useState<number>(0)
  const [isOpenCompareRatesModal, setIsOpenCompareRatesModal] = useState(false)
  const [isOpenReportIssueModal, setIsOpenReportIssueModal] = useState(false)
  const [corporateCodes, setCorporateCodes] = useState<string[]>([])
  const [selectedRateCategories, setSelectedRateCategories] = useState<string[]>([])
  const [selectedHotel, setSelectedHotel] = useState<Nullable<HotelListItem>>()
  const [selectedRate, setSelectedRate] = useState<Nullable<RateDetails>>()
  const [hotelData, setHotelData] = useState<Nullable<Hotel>>()
  const [rateRules, setRateRules] = useState<Nullable<RateRules>>()
  const [initialSearch, setInitialSearch] = useState<boolean>(true)
  const [warningCode, setWarningCode] = useState<string | undefined>()

  const isOTAView = routerLocation.pathname.includes(OTA_VIEW_PATH)

  const isTravelAgent = useIsTravelAgent()
  const [isAgentLoading, setIsAgentLoading] = useState<boolean>(isTravelAgent)

  const isDemoUser = useSelector((state: RootState) => state.auth.isDemoUser)
  const profile = useSelector((state: RootState) => state.auth.profile)

  const {
    setValue: setGds,
    headers,
    value: selectedGdsName,
    id: selectedGdsId,
    setDisabled: setGdsSelectorDisabled,
  } = useGdsSelectContext({
    // TODO: Filter selector values per property ids, e.g. if there will be 3 GDSes.
    disabled: isOpenCompareRatesModal || hotelData?.gds_hotels.length === 1 || isAgentLoading,
  })

  const handleSetGds = useCallback(
    (gdsKey: string) => {
      setGds(gdsKey)
      if (!isOTAView) {
        onSelectTab(DEFAULT_TAB_KEY)
      }
    },
    [setGds],
  )

  useEffect(() => {
    setGdsSelectorDisabled(
      isOpenCompareRatesModal || !hotelData || hotelData?.gds_hotels.length === 1 || isAgentLoading,
    )
  }, [hotelData, isAgentLoading, isOpenCompareRatesModal, setGdsSelectorDisabled])

  useEffect(() => {
    if (initialTravelPortId) {
      handleSetGds(TRAVEL_PORT_KEY)
    } else if (initialSabreId) {
      handleSetGds(SABRE_KEY)
    }
  }, [initialSabreId, initialTravelPortId])

  useEffect(() => {
    if (isDemoUser && profile) {
      setInitialHotelId(profile.hotels[0]?.id)
    }
  }, [profile?.hotels[0]?.id])

  const getRouterPathWithPrefix = useCallback(
    (path: string) => {
      const prefix = userId ? `/admin/users/${userId}` : ''
      return `${prefix}/hotel/${path}`
    },
    [userId],
  )

  const onSelectTab = useCallback(
    (tabKey) => {
      routerPush(getRouterPathWithPrefix(tabKey || DEFAULT_TAB_KEY))
    },
    [routerPush, getRouterPathWithPrefix],
  )

  const { isShownModal: isShownHotelSelectModal, setIsShownModal: setIsShownHotelSelectModal } = useHotelSelectContext({
    shouldVisibleButton: isTablet && !isOpenCompareRatesModal && !isTravelAgent,
  })

  const onFetchSuccessHotelData = useCallback(
    (data: Hotel) => {
      setWarningCode(undefined)
      const foundRate = initialBookingCode
        ? data.rates?.find((rate) => rate.rate_plan === initialBookingCode)
        : undefined

      setHotelData(data)
      setInitialBookingCode(null)
      setInitialHotelId(null)
      setSelectedRate(foundRate || data.rates?.[0])
      setSelectedHotel({ ...data, gds_properties: data.gds_hotels })

      if (isTravelAgent) {
        localStorage.setItem('previouslySelectedHotel', JSON.stringify(data))
        setIsAgentLoading(false)
      }

      if (isOTAView) {
        routerPush(getRouterPathWithPrefix(OTA_VIEW_PATH))
      }
    },
    [initialBookingCode, isTravelAgent, isOTAView],
  )

  const createCheckup = useCreateCheckup({
    queryOptions: {
      onMutate: () => setWarningCode(undefined),
      onSuccess: (data) => onFetchSuccessHotelData(data.data),
      onError: (err) => {
        setInitialSearch(false)
        setSelectedRate(null)
        setRateRules(null)
        setHotelData((prevState) => {
          if (prevState) {
            return { ...prevState, rates: [] }
          }
        })

        if (Object.keys(API_WARNINGS).includes(err.message)) {
          setWarningCode(err.message)
          return
        }

        err.status !== 422 && notification.error({ message: err.message })
      },
    },
  })

  const hotelListResource = useGetHotelListResource({
    headers,
    query: {
      pagination: false,
      only_active: isTravelAgent || isDemoUser ? undefined : !initialHotelId,
      user_id: parseOptionalInt(userId),
    },
    queryOptions: {
      enabled: !isTravelAgent,
    },
  })

  const { data: agentRecentHotelData } = useGetRecentHotelSearchListResource({
    queryOptions: {
      enabled: isTravelAgent,
    },
  })

  const hotelResource = useGetHotelResource({
    params: {
      id: initialHotelId ? Number(initialHotelId) : selectedHotel?.id ?? -1,
    },
    query: {
      checkup_rate_id: parseOptionalInt(initialCheckupRateId),
      travel_port_property_id: initialTravelPortId ? initialTravelPortId : undefined,
      sabre_property_id: initialSabreId ? initialSabreId : undefined,
    },
    headers,
    queryOptions: {
      enabled: !!initialHotelId,
      onSuccess: (data) => onFetchSuccessHotelData(data.data),
      onError: (err) => notification.error({ message: err.message }),
    },
  })

  const hotelList = useMemo(
    () =>
      (isTravelAgent
        ? agentRecentHotelData?.data.concat(hotelResource.data?.data || [])
        : hotelListResource.data?.data) || [],
    [agentRecentHotelData?.data, hotelListResource.data?.data, hotelResource.data?.data, isTravelAgent],
  )

  const { data: checkups, refetch: refetchCheckups } = useGetCheckups(
    headers,
    {
      hotel_id: `${selectedHotel?.id}`,
      start_date: moment().subtract(3, 'month').format('YYYY-MM-DD'),
      end_date: moment().add(1, 'year').format('YYYY-MM-DD'),
    },
    {
      enabled: !!selectedHotel?.id,
    },
  )
  const availableDates = checkups?.data ? checkups.data.map((c) => c.date) : []

  const onRefreshHotel = useCallback(() => {
    onSelectTab(DEFAULT_TAB_KEY)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setHotelData((hotel) => ({ ...hotel, rates: [] }))
    createCheckup.mutate(
      {
        headers,
        body: {
          hotel_id: selectedHotel?.id ?? -1,
          mode: 'full',
          start_date: stringifyMomentDate(selectedStartDate),
          end_date: stringifyMomentDate(selectedEndDate),
          corporate_codes: corporateCodes,
          rate_category_codes: selectedRateCategories,
          adults: adultsCount,
          children: childrenCount,
        },
      },
      {
        onSuccess: () => {
          void refetchCheckups()
        },
        onError: (err) => notification.error({ message: err.message }),
      },
    )
  }, [
    createCheckup,
    headers,
    selectedHotel?.id,
    selectedStartDate,
    selectedEndDate,
    selectedRateCategories,
    corporateCodes,
    refetchCheckups,
    adultsCount,
    childrenCount,
  ])

  const onSelectHotel = (selectedHotel: HotelListItem) => {
    setIsShownHotelSelectModal(false)
    setInitialCheckupRateId(null)
    setSelectedHotel(selectedHotel)
  }

  const isUnavailableRateRulesTab =
    selectedStartDate.isBefore(moment().startOf('day')) && selectedRate && !selectedRate?.details_available

  const rateRulesResource = useGetRateRulesResource({
    headers,
    query: {
      rate_plan: selectedRate?.rate_plan || '',
      hotel_id: selectedHotel?.id ?? -1,
      start_date: hotelData?.checkup?.from_date ?? '',
      end_date: hotelData?.checkup?.to_date ?? '',
      user_id: parseOptionalInt(userId),
    },
    queryOptions: {
      enabled: !!selectedRate && !!hotelData && !isUnavailableRateRulesTab && selectedTabKey === RATE_RULES_TAB.key,
      onError: (err) => {
        notification.error({ message: err.message })
      },
      onSuccess: ({ data }) => {
        setRateRules(data)
        if (!isUnavailableRateRulesTab && !rateRulesResource.isError && selectedRate && data) {
          selectedRate.details_available = true
        }
      },
    },
  })

  useEffect(() => {
    if (selectedHotel?.id) {
      void hotelResource.refetch()
      setInitialSearch(true)
    }
  }, [selectedHotel?.id, headers, hotelResource.refetch]) // eslint-disable-line

  useEffect(() => {
    if (hotelData && hotelData.checkup && !createCheckup.isError && !createCheckup.isLoading) {
      setSelectedStartDate(moment(hotelData.checkup.from_date))
      setSelectedEndDate(moment(hotelData.checkup.to_date))
      setAdultsCount(hotelData.checkup.adults)
      setChildrenCount(hotelData.checkup.children)
      setCorporateCodes(hotelData.checkup.corporate_codes || [])
      setSelectedRateCategories(hotelData.checkup.rate_category_codes?.split('|').filter((item) => item) || [])
      setInitialSearch(false)
    } else if (hotelData && !hotelData.checkup) {
      setInitialSearch(true)
    }
  }, [hotelData, createCheckup.isError, createCheckup.isLoading])

  useEffect(() => {
    if (!selectedHotel && hotelListResource.data?.data && !isTravelAgent) {
      const foundHotel = initialHotelId
        ? hotelListResource.data.data.find((hotel) => hotel.id === parseOptionalInt(initialHotelId))
        : undefined

      setSelectedHotel(foundHotel || hotelListResource.data.data?.[0])
    }
  }, [hotelListResource.data?.data, initialHotelId, selectedHotel])

  useEffect(() => {
    const getDataFromStorage = async () => {
      if (isTravelAgent && !initialHotelId && !selectedHotel) {
        const previouslySelectedHotel = await localStorage.getItem('previouslySelectedHotel')
        setSelectedHotel(previouslySelectedHotel ? JSON.parse(previouslySelectedHotel) : null)
        setIsAgentLoading(false)
      }
    }

    getDataFromStorage()
  }, [isTravelAgent, initialHotelId, selectedHotel])

  useEffect(() => {
    if (routerLocation.search) {
      routerReplace({
        pathname: routerLocation.pathname,
        search: '',
      })
    }
  }, [routerLocation, routerReplace])

  useEffect(() => {
    setHotelData(null)
  }, [selectedGdsName])

  useEffect(() => {
    if (!isTablet) {
      setIsShownHotelSelectModal(false)
    }
  }, [isTablet, setIsShownHotelSelectModal])

  const handleDateChange = useCallback(
    (date) => {
      setInitialCheckupRateId(null)
      setSelectedStartDate(date)
    },
    [checkups?.data],
  )

  const handleBookingCodeSelected = useCallback(
    (value: string) => {
      if (!isOTAView) {
        onSelectTab(DEFAULT_TAB_KEY)
      }
      const foundRate = hotelData?.rates?.find((rate) => rate.rate_plan === value)

      setSelectedRate(foundRate)
      setRateRules(undefined)
    },
    [hotelData, isOTAView],
  )

  // TODO: handle error 404
  // TODO: Refactor

  if (hotelResource.isLoading || (isAgentLoading && !hotelData)) {
    return <PageLoader />
  }

  if (isTravelAgent && !hotelData && !selectedHotel && !hotelResource.isLoading && !isAgentLoading) {
    return (
      <NoData title="No searches yet">
        <Link to="/search/hotel">
          <span className={styles.findHotelMessage}>
            Go to hotel search <ArrowRightAlt />
          </span>
        </Link>
      </NoData>
    )
  }

  if (!hotelData) {
    return <PageLoader />
  }

  if (isShownHotelSelectModal) {
    return (
      <HotelSelectModal
        onClose={() => setIsShownHotelSelectModal(false)}
        hotelList={hotelListResource.data?.data || []}
        selectedHotelId={hotelData.id}
        onSelectHotel={onSelectHotel}
      />
    )
  }

  if (isOpenCompareRatesModal && selectedRate && selectedHotel) {
    return (
      <CompareRatesModal
        hotel={selectedHotel}
        onClose={() => setIsOpenCompareRatesModal(false)}
        corporateCodes={corporateCodes}
        firstRateToCompare={selectedRate}
        startDateFirstRate={selectedStartDate}
        endDateFirstRate={selectedEndDate}
        adultsCount={adultsCount}
        childrenCount={childrenCount}
      />
    )
  }

  return (
    <div className={styles.root}>
      {!isOTAView && (
        <>
          <Params
            availableDates={availableDates}
            selectedStartDate={initialSearch ? moment().add(14, 'days') : selectedStartDate}
            selectedEndDate={initialSearch ? moment().add(15, 'days') : selectedEndDate}
            onSelectStartDate={handleDateChange}
            onSelectEndDate={setSelectedEndDate}
            onRefreshHotel={onRefreshHotel}
            onChangeCorporateCodes={setCorporateCodes}
            onAdultsCountChanged={setAdultsCount}
            onChildrenCountChanged={setChildrenCount}
            corporateCodes={corporateCodes}
            isLoading={createCheckup.isLoading}
            adultsCount={adultsCount}
            childrenCount={childrenCount}
            selectedRateCategories={selectedRateCategories}
            onSelectRateCategories={setSelectedRateCategories}
            hotels={hotelList.map((item) => ({ created_at: '', gds_properties: [], ...item }))}
            selectedHotel={hotelData}
            onSelectHotel={onSelectHotel}
            isCheckupLoaded={!!hotelData?.checkup}
          />

          <Header
            hotel={hotelData}
            showOTAView={() => routerPush(getRouterPathWithPrefix(OTA_VIEW_PATH))}
            areRateRulesAvailable={!!selectedRate?.details_available || !!rateRules}
            selectedDate={selectedStartDate}
            selectedRate={selectedRate}
          />
        </>
      )}
      {isOTAView && (
        <OTAView
          hotel={hotelData}
          selectedRate={selectedRate}
          userId={parseOptionalInt(userId)}
          onNewBookingCode={handleBookingCodeSelected}
        />
      )}
      {!isOTAView && (
        <div className="bg-bg-light pb-4 px-2 flex flex-col gap-4 md:px-7 xl:flex-row">
          {!initialSearch && !createCheckup.isError && !warningCode && (hotelData?.rates || []).length > 0 && (
            <BookingCodeList
              selectedRate={selectedRate}
              onSelectRate={(rate) => {
                onSelectTab(DEFAULT_TAB_KEY)
                setSelectedRate(rate)
              }}
              rateList={hotelData.rates || []}
              disabled={rateRulesResource.isFetching || createCheckup.isLoading}
              // useSkeleton
            />
          )}
          {initialSearch && isTravelAgent && createCheckup.isLoading && <PageLoader />}
          <div className="flex-1">
            {selectedRate ? (
              <div>
                <div className="flex items-center justify-start">
                  <Tabs
                    items={TABS}
                    onChange={onSelectTab}
                    selectedKey={selectedTabKey || DEFAULT_TAB_KEY}
                    className="pt-0"
                  />

                  {isTravelAgent && (
                    <div className="ml-auto">
                      <NotifyIssueActionButtons
                        showReportIssueModal={() => setIsOpenReportIssueModal(true)}
                        rateId={selectedRate?.id || -1}
                      />
                    </div>
                  )}

                  {/*{!isTravelAgent && (*/}
                  {/*  <div className="ml-auto">*/}
                  {/*    <Button onClick={() => setIsOpenCompareRatesModal(true)}>*/}
                  {/*      <span className="inline-flex items-center gap-2">*/}
                  {/*        Compare Rates <ArrowLongRightIcon className="w-4 hidden sm:block" />*/}
                  {/*      </span>*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>

                <div className="bg-white px-2 py-5 flex flex-col gap-5 lg:px-4">
                  {selectedTabKey === 'details' && selectedRate && (
                    <RateDetailsTab
                      rateDetails={createCheckup.isLoading ? null : selectedRate}
                      isLoading={createCheckup.isLoading || hotelResource.isLoading}
                      showCompareRatesModal={() => setIsOpenCompareRatesModal(true)}
                      checkup={hotelData.checkup}
                      onRefreshHotel={onRefreshHotel}
                      selectedDate={selectedStartDate}
                    />
                  )}

                  {selectedTabKey === 'rules' &&
                    selectedRate &&
                    (!rateRulesResource.isFetching &&
                    !rateRulesResource.isLoading &&
                    (isUnavailableRateRulesTab || rateRulesResource.isError || !rateRules) ? (
                      <Unavailable />
                    ) : (
                      <RateRulesTab
                        rateDetails={selectedRate}
                        isLoading={
                          rateRulesResource.isFetching ||
                          rateRulesResource.isLoading ||
                          createCheckup.isLoading ||
                          hotelResource.isLoading
                        }
                        rateRules={rateRules}
                        checkup={hotelData.checkup}
                        onRefreshRules={rateRulesResource.refetch}
                        selectedDate={selectedStartDate}
                      />
                    ))}
                </div>
              </div>
            ) : (
              !initialSearch &&
              (selectedTabKey === 'details' || (createCheckup.isError && !createCheckup.isLoading) ? (
                <div className="py-9 bg-white">
                  <Unavailable
                    title={warningCode ? API_WARNINGS[warningCode].title : 'No data for selected GDS and hotel.'}
                    image={!warningCode ? noDataImage : unavailableImage}
                  >
                    {warningCode ? API_WARNINGS[warningCode].message : 'Select different date to load rates.'}
                  </Unavailable>
                </div>
              ) : (
                <div className="py-9 bg-white">
                  <Unavailable />
                </div>
              ))
            )}
            {initialSearch && !createCheckup.isLoading && (
              <div className="flex flex-col items-center gap-12 rounded-lg bg-white p-4 md:pt-12 md:pb-24">
                <img src={noDataImage} alt="image" />

                <div className="text-center">
                  <h1 className="text-3xl leading-9 font-semibold text-gray-800">No searches yet</h1>
                  <div className="text-base leading-none font-normal text-gray-500">
                    Select parameters and click &#39;Search&#39;
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isOpenReportIssueModal && (
        <ReportIssueModal
          rateId={selectedRate?.id || -1}
          hotelName={hotelData.name}
          hotelId={hotelData.id}
          gdsHotel={hotelData.gds_hotels.find((gdsHotel) => gdsHotel.gds_id === selectedGdsId)}
          bookingCode={selectedRate?.rate_plan || ''}
          onClose={() => setIsOpenReportIssueModal(false)}
          xlsDate={selectedStartDate}
        />
      )}
    </div>
  )
}

export default HotelPage
