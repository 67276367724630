import React, { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import moment, { Moment } from 'moment'
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline'

import { Checkup } from 'types/domain/Checkup'
import { Button } from 'ui-v2'

type Props = {
  checkup?: Checkup
  onRefreshHotel?: (hotelID?: number) => void
  selectedDate?: Moment
  checkupName?: string
}

const CheckupDetails = ({ checkup, onRefreshHotel, selectedDate, checkupName = 'Rates' }: Props) => {
  const fromDate = useMemo(() => moment(checkup?.from_date).format('D MMM YYYY'), [checkup?.from_date])
  const stayDurationDays = useMemo(
    () => Math.ceil(moment.duration(moment(checkup?.to_date).diff(moment(checkup?.from_date))).asDays()),
    [checkup],
  )

  return (
    <div className="flex items-center gap-4">
      {onRefreshHotel && (
        <Button
          onClick={() => onRefreshHotel()}
          variant="secondary"
          className="border-none p-0 h-8 w-8 inline-flex items-center justify-center"
          isDisabled={!selectedDate || selectedDate?.isBefore(moment().startOf('day'))}
        >
          <ArrowPathRoundedSquareIcon className="w-5" />
        </Button>
      )}
      <div className={twMerge('text-base leading-none font-normal text-gray-500', !onRefreshHotel && 'pl-2')}>
        {checkupName} for <span className="text-gray-700">{fromDate}</span>,{' '}
        <span className="text-gray-700">{stayDurationDays}</span> nights,{' '}
        {checkup?.adults && (
          <>
            <span className="text-gray-700">{checkup?.adults}</span> adults,{' '}
          </>
        )}
        {checkup?.children ? (
          <>
            <span className="text-gray-700">{checkup?.children}</span> children,{' '}
          </>
        ) : null}
        {checkup?.corporate_codes?.length > 0 && (
          <>
            RAC: <span className="text-gray-700">{checkup?.corporate_codes?.map((code) => `${code}, `)}</span>
          </>
        )}
        <br className="md:hidden" />
        loaded on <span className="text-indigo-800">{moment(checkup?.created_at).format('D MMM YYYY')}</span>
        {', '}
        <span className="text-indigo-800">{moment(checkup?.created_at).format('HH:mm')}</span>
      </div>
    </div>
  )
}

export default CheckupDetails
