import React, { useMemo } from 'react'
import {
  AssignmentLate,
  Description,
  Fastfood,
  FreeBreakfast,
  Hotel,
  Image,
  MonetizationOn,
  Note,
  QueryBuilder,
  SmokingRooms,
  Subtitles,
} from '@material-ui/icons'
import { Moment } from 'moment'

import { CURRENCY_CODES } from 'constants/currency'
import useGdsSelectContext from 'components/GdsSelect/useGdsSelectContext'
import { RateRules } from 'types/domain/RateRules'
import { CheckupDetails } from '../CheckupDetails'
import { Checkup } from 'types/domain/Checkup'
import { RateDetails } from 'types/domain/RateDetails'
import List from 'modules/HotelsModule/pages/HotelPage/components/List'
import { useGetHighlightedPhrasesResource } from 'api/highlightedPhrases/getHighlightedPhrasesResource'
import { parseCancellationDeadlineOffsetObjectToString } from 'helpers/cancellationDeadlineOffset'

import { Skeleton } from './components'

type Props = {
  isLoading: boolean
  rateRules: Nullable<RateRules>
  rateDetails: Nullable<RateDetails>
  checkup?: Checkup
  onRefreshRules?: () => void
  selectedDate?: Moment
}

const RateRulesTab = (props: Props) => {
  const { isLoading, rateRules, rateDetails, checkup, onRefreshRules, selectedDate } = props
  const { isSelectedSabre } = useGdsSelectContext()
  const { data: highlightedPhrases } = useGetHighlightedPhrasesResource({})

  const sections = useMemo(() => {
    if (!rateRules) {
      return []
    }

    return [
      {
        label: 'Promotion',
        fields: [
          {
            icon: <Note />,
            title: 'Promo text:',
            values: [rateRules?.gds_promotion_field],
            highlightPhrases: true,
          },
        ],
      },
      {
        label: 'Rate',
        fields: [
          {
            icon: <Subtitles />,
            title: 'Booking code:',
            values: [rateDetails?.rate_plan],
            highlightPhrases: true,
          },
          {
            icon: <Description />,
            title: 'Rate description:',
            values: [rateRules?.rate_description],
            highlightPhrases: true,
          },
          {
            icon: <Description />,
            title: 'Room rate description:',
            values: [rateRules?.room_rate_description],
            highlightPhrases: true,
          },
          {
            icon: <Description />,
            title: 'Room details:',
            values: [rateRules?.room_detail],
            highlightPhrases: true,
          },
          isSelectedSabre
            ? null
            : {
                icon: <Subtitles />,
                title: 'Location info:',
                values: [rateRules?.location_info],
                highlightPhrases: true,
              },
          isSelectedSabre
            ? null
            : {
                icon: <Description />,
                title: 'Property description:',
                values: [rateRules?.property_description],
                highlightPhrases: true,
              },
          {
            icon: <Image />,
            title: 'Room view:',
            values: [rateRules?.room_view?.value],
            highlightPhrases: true,
          },
          {
            icon: <SmokingRooms />,
            title: 'Smoking room:',
            values: [rateRules?.smoking === true ? 'Yes' : rateRules?.smoking === false ? 'No' : undefined],
            highlightPhrases: true,
          },
          {
            icon: <Hotel />,
            title: 'Bed types:',
            values:
              !Array.isArray(rateRules.beds) ||
              rateRules.beds.length < 1 ||
              (rateRules.beds.length > 0 && !rateRules.beds[0])
                ? []
                : rateRules.beds.map((bed) => `${bed.type} x ${bed.quantity}`),
            highlightPhrases: true,
          },
        ],
      },
      {
        label: 'Meal Plan',
        fields: [
          {
            icon: <Fastfood />,
            title: 'Breakfast:',
            values: [rateRules?.breakfast === true ? 'Yes' : rateRules?.breakfast === false ? 'No' : undefined],
            highlightPhrases: true,
          },
          {
            icon: <FreeBreakfast />,
            title: 'Lunch:',
            values: [rateRules?.lunch === true ? 'Yes' : rateRules?.lunch === false ? 'No' : undefined],
            highlightPhrases: true,
          },
          {
            icon: <Fastfood />,
            title: 'Dinner:',
            values: [rateRules?.dinner === true ? 'Yes' : rateRules?.dinner === false ? 'No' : undefined],
            highlightPhrases: true,
          },
        ],
      },
      {
        label: 'Rate Breakdown',
        fields: [
          {
            icon: <MonetizationOn />,
            title: 'Rate base all days:',
            values: [],
            sublines: [
              { title: 'Base', values: [rateRules?.rate_base], highlightPhrases: true },
              { title: 'Total', values: [rateRules?.rate_total], highlightPhrases: true },
            ],
          },
          {
            icon: <MonetizationOn />,
            title: 'Taxes:',
            values: [/\d/.test(rateRules?.taxes) ? rateRules.taxes : undefined],
            highlightPhrases: true,
          },
          isSelectedSabre
            ? null
            : {
                icon: <MonetizationOn />,
                title: 'Surcharges:',
                values: [rateRules?.surcharges],
                highlightPhrases: true,
              },
          isSelectedSabre
            ? null
            : {
                icon: <MonetizationOn />,
                title: 'Total:',
                values: [rateRules?.total],
                highlightPhrases: true,
              },
          {
            icon: <QueryBuilder />,
            title: 'Rate comment:',
            values:
              rateRules?.rate_comment?.split(/[\r\n]+/).reduce((acc, item) => {
                const lastSpaceIndex = item.lastIndexOf(' ')

                if (lastSpaceIndex !== -1) {
                  const currencyPart = item.slice(lastSpaceIndex + 1)
                  const textPart = item.slice(0, lastSpaceIndex)

                  return Object.keys(CURRENCY_CODES).includes(currencyPart.slice(0, 3))
                    ? [...acc, textPart, currencyPart]
                    : [...acc, item]
                }

                return [...acc, item]
              }, [] as string[]) || [],
            highlightPhrases: true,
          },
          {
            icon: <MonetizationOn />,
            title: 'Extra charges:',
            values: [rateRules?.extra_charges],
            highlightPhrases: true,
          },
        ],
      },
      {
        label: 'Commission',
        fields: [
          {
            icon: <MonetizationOn />,
            title: 'Commission:',
            values: [
              rateRules
                ? rateRules.commission_type !== 'unknown' && rateRules.commission_type !== null
                  ? isSelectedSabre
                    ? rateRules.commission_type !== 'None'
                      ? 'Yes'
                      : 'No'
                    : Boolean(rateRules.commission_type)
                    ? 'Yes'
                    : 'No'
                  : undefined
                : undefined,
            ],
            sublines: [
              {
                title: 'Commission value:',
                values: [
                  rateRules && rateRules.commission_type !== 'unknown' && rateRules.commission_type !== null
                    ? (isSelectedSabre ? rateRules.commission_type !== 'None' : Boolean(rateRules.commission_type))
                      ? rateRules.commission_value
                        ? rateRules.commission_value
                        : undefined
                      : '-'
                    : undefined,
                ],
              },
            ],
          },
          {
            icon: <MonetizationOn />,
            title: 'Rate guarantee info:',
            values: [rateRules?.guarantee_type, rateRules?.guarantee_deadline],
            highlightPhrases: true,
          },
          isSelectedSabre
            ? null
            : {
                icon: <MonetizationOn />,
                title: 'Deposit amount:',
                values: [rateRules?.deposit_amount],
                highlightPhrases: true,
              },
          {
            icon: <Subtitles />,
            title: 'Credit card for guarantee:',
            values: [rateRules?.credit_card_deposit_guarantee],
            highlightPhrases: true,
          },
          {
            icon: <AssignmentLate />,
            title: 'Cancellation policy:',
            values: [],
            sublines: [
              { title: 'Info:', values: [rateRules?.cancellation_info], highlightPhrases: true },
              isSelectedSabre
                ? null
                : { title: 'Check-in - Check-out:', values: [rateRules?.checkin_checkout], highlightPhrases: true },
              {
                title: 'Refundable:',
                values: [
                  typeof rateRules?.cancellation_policy === 'boolean'
                    ? rateRules.cancellation_policy
                      ? 'Yes'
                      : 'No'
                    : undefined,
                ],
                highlightPhrases: true,
              },
              {
                title: 'Cancellation deadline:',
                values: [
                  rateRules?.cancellation_deadline ||
                    (rateRules?.cancellation_deadline_offset
                      ? parseCancellationDeadlineOffsetObjectToString(rateRules?.cancellation_deadline_offset)
                      : undefined),
                ],
                highlightPhrases: true,
              },
              isSelectedSabre
                ? null
                : {
                    title: 'Absolute cancel deadline:',
                    values: [rateRules?.guarantee_deadline],
                    highlightPhrases: true,
                  },
              isSelectedSabre
                ? null
                : { title: 'Cancel penalty:', values: [rateRules?.cancel_penalty_amount], highlightPhrases: true },
            ],
          },
          {
            icon: <Subtitles />,
            title: 'Accepted payment:',
            values: [rateRules?.accepted_payment],
            highlightPhrases: true,
          },
        ],
      },
    ]
  }, [isSelectedSabre, rateDetails?.rate_plan, rateRules])

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      <CheckupDetails
        checkup={checkup && rateRules ? { ...checkup, created_at: rateRules?.created_at || '' } : undefined}
        onRefreshHotel={onRefreshRules}
        selectedDate={selectedDate}
        checkupName="Rules"
      />
      <List
        sections={sections}
        showErrors={!isLoading && !!rateDetails}
        highlightedPhrases={highlightedPhrases?.data}
      />
    </>
  )
}

export default RateRulesTab
