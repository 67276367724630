import React from 'react'

import { ValueRow } from 'ui-v2'

export const Skeleton = () => (
  <div className="flex flex-col gap-2 pl-5 pr-2">
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
    <ValueRow extraLength={50} />
  </div>
)
