import React, { useMemo, useState, Fragment, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { twMerge } from 'tailwind-merge'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Close as CloseIcon } from '@material-ui/icons'

import { useIsTravelAgent } from 'hooks'
import { useGetGdsRateCategoryListResource } from 'api/gds/getGdsListResource'
import { DateRangePicker, Select, MultiSelect, Input, Button, CommaSeparatedInput } from 'ui-v2'
import { Hotel } from 'types/domain/Hotel'
import { HotelListItem } from 'types/domain/HotelListItem'
import filterIcon from 'assets/filter.svg'

export type Props = {
  selectedStartDate: Moment
  selectedEndDate: Moment
  onSelectStartDate: (date: Moment) => void
  onSelectEndDate: (date: Moment) => void
  onRefreshHotel: () => void
  corporateCodes: string[]
  onChangeCorporateCodes: (value: string[]) => void
  isLoading?: boolean
  availableDates: string[]
  onAdultsCountChanged: (count: number) => void
  onChildrenCountChanged: (count: number) => void
  adultsCount: number
  childrenCount: number
  selectedRateCategories: string[]
  onSelectRateCategories: (value: string[]) => void
  hotels: HotelListItem[]
  onSelectHotel: (selectedHotel: HotelListItem) => void
  selectedHotel: Hotel
  isCheckupLoaded: boolean
}

const Params = (props: Props) => {
  const {
    selectedStartDate,
    selectedEndDate,
    corporateCodes,
    onChangeCorporateCodes,
    onSelectStartDate,
    onSelectEndDate,
    onRefreshHotel,
    onAdultsCountChanged,
    onChildrenCountChanged,
    isLoading,
    availableDates,
    adultsCount,
    childrenCount,
    selectedRateCategories,
    onSelectRateCategories,
    hotels,
    selectedHotel,
    onSelectHotel,
    isCheckupLoaded,
  } = props
  const isTravelAgent = useIsTravelAgent()
  const history = useHistory()

  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false)
  const [selectedHotelOptionValue, setSelectedHotelOptionValue] = useState('')
  const [currentRangePickerValues, setCurrentRangePickerValues] = useState([selectedStartDate, selectedEndDate])

  const { data: gdsRateCategoriesData } = useGetGdsRateCategoryListResource()

  const categoryRatesOptions = useMemo(
    () =>
      gdsRateCategoriesData?.data
        .filter((item) => item.active)
        .map((item) => ({
          value: item.code,
          label: item.name,
        })) || [],
    [gdsRateCategoriesData],
  )

  const selectedHotelsOptions = useMemo(
    () =>
      hotels.map((item) => ({
        value: item.id.toString(),
        label: item.name,
      })) || [],
    [hotels],
  )

  useEffect(() => {
    setSelectedHotelOptionValue(selectedHotel.id.toString())
  }, [selectedHotel.id])

  const isHotelChanged = Number(selectedHotelOptionValue) !== selectedHotel?.id

  useEffect(() => {
    if (!isLoading && isCheckupLoaded) {
      setCurrentRangePickerValues([selectedStartDate, selectedEndDate])
    }
  }, [selectedStartDate, selectedEndDate])

  const onRangePickerCalendarChange = useCallback((values) => {
    setCurrentRangePickerValues(values)
    // OPTIONAL: SET DAY RANGE AUTOMATICALLY
    //
    // const amountOfDefaultDayRange = 2
    // if (!values[1] && values[0]) {
    //   setCurrentRangePickerValues((prevState) => [prevState[0], moment(prevState[0]).add(amountOfDefaultDayRange, 'days')])
    // }
    //
    // if (!values[0] && values[1]) {
    //   setCurrentRangePickerValues((prevState) => [moment(prevState[1]).add(-amountOfDefaultDayRange, 'days'), prevState[1]])
    // }
    //
    // END
  }, [])

  const onRangePickerChange = useCallback(
    (dates) => {
      if (dates?.[0] && dates?.[1]) {
        onSelectEndDate(dates?.[1])
        onSelectStartDate(dates?.[0])
      }
    },
    [onSelectEndDate, onSelectStartDate],
  )

  const onSubmit = () => {
    if (isHotelChanged) {
      const hotelItem = hotels.find((item) => item.id === Number(selectedHotelOptionValue))
      hotelItem && onSelectHotel(hotelItem)
    } else {
      onRefreshHotel()
    }
  }

  return (
    <div className="p-4 bg-bg-accent-7 flex flex-col gap-3 md:px-7 xl:flex-row">
      <div className={twMerge('xl:hidden', areFiltersExpanded && 'mb-4')}>
        <div className="flex items-center justify-between gap-2 mb-2">
          <DateRangePicker
            className="border-indigo-300 flex-1"
            labels={['Check-in', 'Check-out']}
            value={currentRangePickerValues}
            onChange={onRangePickerChange}
            minDate={moment()}
            maxDate={moment().add(330, 'days')}
            availableDates={availableDates}
            isDisabled={isHotelChanged}
            onCalendarChange={onRangePickerCalendarChange}
          />
          <Button
            className="w-10 rounded p-0 flex items-center justify-center"
            onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
            size="large"
            variant="primary"
          >
            {areFiltersExpanded ? <CloseIcon className="w-5" /> : <img src={filterIcon} className="w-5" />}
          </Button>
          <Button
            isLoading={isLoading}
            className="w-10 rounded p-0 flex items-center justify-center"
            isDisabled={
              areFiltersExpanded ||
              (currentRangePickerValues && currentRangePickerValues[0]
                ? currentRangePickerValues[0].isBefore(moment().startOf('day')) && !isHotelChanged
                : true)
            }
            onClick={onSubmit}
            size="large"
            variant="primary"
          >
            <MagnifyingGlassIcon className="w-5" />
          </Button>
        </div>

        <button
          className="bg-transparent hidden md:block border-none text-sm leading-4 font-medium text-blue-800"
          onClick={() => setAreFiltersExpanded((prevState) => !prevState)}
        >
          {areFiltersExpanded ? 'Less' : 'More'} filters
        </button>
      </div>

      <Select
        className={twMerge(
          'border-indigo-300 w-full xl:block xl:w-auto xl:min-w-40 flex-1 flex-shrink-0',
          !areFiltersExpanded && 'hidden',
        )}
        isSearchable
        options={[
          ...(isTravelAgent
            ? [
                {
                  value: '',
                  label: (
                    <Button variant="secondary" onClick={() => history.push('/search/hotel')} className="w-full">
                      Go to search
                    </Button>
                  ),
                },
              ]
            : []),
          ...selectedHotelsOptions,
        ]}
        value={selectedHotelOptionValue.toString()}
        label="Selected hotel"
        onChange={(value) => {
          value && setSelectedHotelOptionValue(value)
          onSelectHotel({ id: value })
        }}
      />

      <DateRangePicker
        className="border-indigo-300 hidden xl:block xl:w-64 flex-shrink-0"
        labels={['Check-in', 'Check-out']}
        value={currentRangePickerValues}
        onChange={onRangePickerChange}
        minDate={moment()}
        maxDate={moment().add(330, 'days')}
        availableDates={availableDates}
        isDisabled={isHotelChanged}
        onCalendarChange={onRangePickerCalendarChange}
      />

      <MultiSelect
        className={twMerge(
          'border-indigo-300 w-full xl:block xl:w-auto xl:min-w-40 flex-1 flex-shrink-0',
          !areFiltersExpanded && 'hidden',
        )}
        options={categoryRatesOptions}
        placeholder="All"
        label="Rate category"
        value={selectedRateCategories}
        onChange={(value) => {
          value.length <= 8 && onSelectRateCategories(value)
        }}
        isDisabled={isHotelChanged}
      />

      <CommaSeparatedInput
        label="RAC"
        values={corporateCodes}
        onChange={onChangeCorporateCodes}
        maxValues={3}
        isDisabled={isHotelChanged}
        className={twMerge(
          'h-[42px] pt-[15px] border-indigo-300 w-full xl:w-[14rem] xl:block xl:w-3xs flex-shrink-0',
          !areFiltersExpanded && 'hidden',
        )}
      />

      <Menu
        as="div"
        className={twMerge(
          'relative rounded border border-indigo-300 w-full xl:block xl:w-56 flex-shrink-0',
          !areFiltersExpanded && 'hidden',
          isHotelChanged && 'pointer-events-none border-gray-100',
        )}
      >
        {({ open }) => (
          <>
            <Menu.Button
              className={twMerge(
                'flex items-center justify-between p-1 h-[40px] overflow-hidden border-none bg-white w-full',
              )}
            >
              <MagnifyingGlassIcon className="text-blue-800 w-4 mx-1" />
              <Input
                value={adultsCount.toString()}
                label="Adults"
                className="border-none w-20 pointer-events-none flex-1"
                readOnly
                isDisabled={isHotelChanged}
              />
              <Input
                value={childrenCount.toString()}
                label="Children"
                className="border-none w-20 pointer-events-none flex-1"
                readOnly
                isDisabled={isHotelChanged}
              />
              <div
                className={twMerge(
                  'bg-indigo-100 border border-indigo-300 flex items-center justify-center text-blue-800 rounded-sm w-[32px] h-[32px]',
                  open && 'bg-indigo-300 rotate-180',
                  isHotelChanged && 'bg-gray-50 border-gray-200 text-gray-400',
                )}
              >
                <ChevronDownIcon className="w-6" />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-1 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-3 w-full flex flex-col gap-3 text-gray-700">
                  <div className="w-full flex items-center justify-between">
                    Adults:
                    <div className="flex items-center justify-between border border-indigo-300 rounded">
                      <button
                        className="bg-white border-none text-blue-800 text-base disabled:text-gray-500"
                        disabled={adultsCount <= 1}
                        onClick={() => onAdultsCountChanged(adultsCount - 1)}
                      >
                        -
                      </button>
                      <input
                        value={adultsCount}
                        className="w-10 text-center text-base leading-none font-normal border-none pointer-events-none"
                      />
                      <button
                        className="bg-white border-none text-blue-800 text-base disabled:text-gray-500"
                        onClick={() => onAdultsCountChanged(adultsCount + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="w-full flex items-center justify-between">
                    Children:
                    <div className="flex items-center justify-between border border-indigo-300 rounded">
                      <button
                        className="bg-white border-none text-blue-800 text-base disabled:text-gray-500"
                        disabled={childrenCount <= 0}
                        onClick={() => onChildrenCountChanged(childrenCount - 1)}
                      >
                        -
                      </button>
                      <input
                        value={childrenCount}
                        className="w-10 text-center text-base leading-none font-normal border-none pointer-events-none"
                      />
                      <button
                        className="bg-white border-none text-blue-800 text-base disabled:text-gray-500"
                        onClick={() => onChildrenCountChanged(childrenCount + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <Button
        className={twMerge('xl:block xl:w-auto', !areFiltersExpanded && 'hidden')}
        onClick={onSubmit}
        isLoading={isLoading}
        variant="primary"
        size="large"
        isDisabled={
          currentRangePickerValues && currentRangePickerValues[0]
            ? currentRangePickerValues[0].isBefore(moment().startOf('day')) && !isHotelChanged
            : true
        }
      >
        Search
      </Button>
    </div>
  )
}

export default Params
