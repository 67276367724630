import React, { useState, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { Close } from '@material-ui/icons'

type CommaSeparatedInputProps = {
  label: string
  values: string[]
  onChange: (values: string[]) => void
  maxValues: number
  error?: string | null
  isDisabled?: boolean
  readOnly?: boolean
  className?: string
}

export const CommaSeparatedInput: React.FC<CommaSeparatedInputProps> = ({
  label,
  values,
  onChange,
  maxValues,
  error,
  isDisabled,
  readOnly,
  className,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [localError, setLocalError] = useState<string | null>(null)

  useEffect(() => {
    if (error) {
      setLocalError(error)
    }
  }, [error])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalError(null)
    const { value } = e.target
    if (value.includes(',')) {
      const parts = value.split(',')
      const newValuesToAdd = parts
        .slice(0, -1)
        .map((val) => val.trim())
        .filter((val) => val.length > 0)

      if (values.length + newValuesToAdd.length === maxValues) {
        setLocalError(`Max values reached: ${maxValues}`)
        const canAddCount = maxValues - values.length
        const partialAllowed = newValuesToAdd.slice(0, canAddCount)
        onChange([...values, ...partialAllowed])
      } else {
        onChange([...values, ...newValuesToAdd])
      }

      setInputValue(parts[parts.length - 1])
    } else {
      setInputValue(value)
    }
  }

  const handleBlur = () => {
    if (inputValue.trim()) {
      if (values.length >= maxValues) {
        setLocalError(`Max values reached: ${maxValues}`)
        return
      }

      onChange([...values, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addValueFromInput()
    }
  }

  const addValueFromInput = () => {
    if (!inputValue.trim()) {
      return
    }

    if (values.length >= maxValues) {
      setLocalError(`Max values reached: ${maxValues}`)
      return
    }

    onChange([...values, inputValue.trim()])
    setInputValue('')
  }

  const removeValue = (index: number) => {
    const newValues = values.filter((_, idx) => idx !== index)
    onChange(newValues)
    setLocalError(null)
  }

  return (
    <div
      className={twMerge(
        'flex flex-column relative',
        isDisabled && 'border-gray-100',
        error && 'border-validation-error-outline mb-4',
      )}
    >
      <div className={twMerge('relative bg-white rounded border border-gray-100 p-2', className)}>
        <label
          className={twMerge(
            'absolute top-0.5 left-2 bg-transparent px-1 text-xs text-gray-500',
            isDisabled && 'text-gray-300',
          )}
        >
          {label}:
        </label>

        <div className="flex flex-wrap items-center gap-2 mt-0.5">
          {values.map((val, idx) => (
            <div key={idx} className="flex items-center bg-blue-100 text-blue-800 rounded-md px-1.5 text-sm">
              <span>{val}</span>
              {!readOnly && !isDisabled && (
                <button
                  type="button"
                  onClick={() => removeValue(idx)}
                  className="text-xs text-blue-600 hover:text-red-800 p-0 -ml-1 border-none w-[16px] h-[16px] flex items-center"
                >
                  <Close className="scale-50" />
                </button>
              )}
            </div>
          ))}

          {!readOnly && maxValues !== values.length && (
            <input
              type="text"
              className={twMerge(
                'flex-1 min-w-[50px] py-0 px-0 pl-0.5 focus:outline-none',
                'pl-1 text-gray-800 text-sm placeholder:text-gray-400',
                'border-none focus:ring-0',
              )}
              placeholder={values.length === 0 ? 'Enter comma separated codes' : ''}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              disabled={isDisabled || values.length >= maxValues}
            />
          )}
        </div>
      </div>

      {localError && (
        <span className="text-validation-error absolute -bottom-4 left-3 text-xs whitespace-nowrap overflow-ellipsis w-full overflow-hidden">
          {localError}
        </span>
      )}
    </div>
  )
}
